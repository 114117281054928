<template>
    <v-container class="format">
        <v-card  max-width="550px">
        <alert-message v-if="msg" :error="msg"></alert-message>
        <v-flex text-center>
            <h2>Reset Password</h2>
        </v-flex>
        <v-card-text>
            <v-form ref="form" lazy-validation >
                <label >{{item.email}}</label>
                <v-text-field type="password" v-if="show" v-model="oldPassword" :rules="oldPasswordRule"  label="Enter old password" required></v-text-field>
                <v-text-field type="password" v-model="newPassword" :rules="newPasswordRule" label="Enter New password" required></v-text-field>
                <v-text-field type="password" v-model="confirmPassword" :rules="passwordConfirmationRule" :error="passwordError" :error-messages="passwordErrorMsg" label="Confirm password"></v-text-field>
                <v-btn :style="theme" block @click="submit()">Save</v-btn>
                <v-btn class="mt-4" :style="theme" block @click="$router.go(-1)">Cancel</v-btn>
            </v-form>
        </v-card-text>
        <alert-message v-if="msg" :error="msg"></alert-message>
        </v-card>
    </v-container>
</template>

<script>
import appConstants from "@/utils/appConstants.js";
export default {
    data() {
        return {
            oldPassword:"",
            newPassword: "",
            confirmPassword: "",
            passwordError: null,
            passwordErrorMsg: "",
            item: {},
            id: 0,
            show:true
        }
    },
    mounted() {
        this.initComponent()
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
            this.show=this.$route.params.show
            this.item = await this.getItem(appConstants.STAFFS_API + "/" + this.id)
        },
        submit() {
            if(this.newPassword==this.confirmPassword&&this.$refs.form.validate()){
                if(this.show){
                    this.resetStaffPassword()
                }
                else{
                    this.forceReset()
                }
            }
        },
        async resetStaffPassword(){
            try{
                var staff={mobileNo:this.item.mobileNo,oldPassword:this.oldPassword,newPassword:this.newPassword}
                await this.putItem(appConstants.STAFFS_API+"/resetStaffPassword",staff)
                alert("Password updated successfully")
                this.$router.go(-1)
            }catch(error){
                this.handleError(error);
                this.msg=error.response.data
            }
        },
        async forceReset(){
            try {
                var staff={mobileNo:this.item.mobileNo,newPassword:this.newPassword}
                await this.putItem(appConstants.STAFFS_API+"/forceResetPassword",staff)
                alert("Password updated successfully")
                this.$router.go(-1)
            } catch (error) {
                this.handleError(error);
                this.msg=error.response.data
            }
        }
    }
}
</script>

<style scoped>
.format{
    padding-top: 5%;
    padding-left:22%
}
</style>